import React from 'react';
import './App.css';
import "./assets/font-awesome/css/font-awesome.min.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from './route/PrivateRoute';
import Mainpage from "./pages/dashboardPage";
import Loginpage from "./pages/loginPage";
import Navbarpage from "./pages/navbar";
import MasterBarang from "./pages/masterBarangPage";
import MasterVendor from "./pages/masterVendorPage";
import InventoryPage from "./pages/inventory";
import ProduksiPage from "./pages/produksiPage";
import ProductinPage from "./pages/purchaseOrderPage";
import ReceivepoPage from "./pages/receivePoPage";
import ProductoutPage from "./pages/deliveryOrderPage";
import orderPage from "./pages/orderPage";
import userPage from "./pages/userPage";
import reportPage from "./pages/reportPage";
import TestReport from "./pages/testReport";
import purchaseOrderPrint from "./pages/purchaseOrderPrint";



function App() {
  return (
    <div>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Loginpage />
          </Route>
          <PrivateRoute component={Mainpage} path="/home" exact />
          <PrivateRoute component={userPage} path="/user" exact />
          <PrivateRoute component={MasterVendor} path="/mastervendor" exact />
          <PrivateRoute component={MasterBarang} path="/masterbarang" exact />
          <PrivateRoute component={ProductinPage} path="/productin" exact />
          <PrivateRoute component={ReceivepoPage} path="/receive_po" exact />
          <PrivateRoute component={ProduksiPage} path="/produksi" exact />
          <PrivateRoute component={ProductoutPage} path="/productout" exact />
          <PrivateRoute component={orderPage} path="/order" exact />
          <PrivateRoute component={reportPage} path="/report" exact />
          <PrivateRoute component={TestReport} path="/testreport" exact />
          <PrivateRoute component={purchaseOrderPrint} path="/purchaseOrderPrint" exact />
        </Switch>
      </Router>
    </Provider>
    </div>
  );
}

export default App;
